import React from 'react'
import ServiceSidebarmdm from './ServiceSidebarmdm'
import detailsmdm from '../../assets/images/services/services-detailsmdm.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentmdm = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsmdm} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Master Data Management </span>
                            <h3>About this Service</h3>
                            <p>Consistency in master data management strategy is at the forefront of the success of an enterprise. Reporting standardization and regulatory compliance have prompted companies to explore Master Data  </p>
                            <p>Management Strategy solutions. Disparate systems in your environment have different names, thus you will get a variety of results when you are looking for the same thing. Even though you may have a workaround now, over time this will not stick and will result in lost business opportunities. Data is not just a nice to have in business anymore, it is your competitive advantage.</p>
                            <p>To circumvent data inconsistencies you need to identify a single version of truth in your data. The unified source of data will allow you to ask questions and have confidence in the answers your queries provide. Through Master Data Management (MDM) you can have a single view of customers, products, suppliers, inventory, employees and any other variables that are important to you.</p>
                            
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>15+ Implementations</li>
                                            <li>12+ Industry Experts</li>
                                            <li>20+ years of experience </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Master Data Management Strategy Consulting process by Diamonds Consulting includes:</p>
                            <li>Understanding the goals of your business and how an MDM strategy can fulfill them</li>
                            <li>Identifying the critical master data</li>
                            <li>Determining how data is organized and stored</li>
                            <li>Identifying owners of data</li>
                            <li>Evaluating whether a single domain or multi-domain is best</li>
                            <li>Developing the master data model</li>
                            
                            <li>Ensuring quality control</li>
                            <li>Determining whether the Master Data Management strategy implementation is operation, enterprise, or analytics</li>
                            
                            <li>Identifying every task that needs to have gone through a well-developed governance program</li>
                            <p>When it comes to creating a Master Data Management strategy for an enterprise or any business environment, it can get complicated quickly. To ensure the integrity of your data, depend on the years of experience that Diamonds Consulting brings to the table.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Insurance
                                    </div>
                                </div>



                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Health Care

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>

                            </div>
                        

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle Customer Hub</span></li>
                                <li><span>Oracle Product Hub</span></li>
                                <li><span>IBM Master Data Management</span></li>
                                <li><span>And Others</span></li>
                               
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarmdm />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentmdm